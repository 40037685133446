<template>
  <div>
    <div class="starters-list">
      <div v-if="isGettingStarterList">Getting starters from MDA, please wait...</div>
      <div class="starter-item" v-for="starter in starterList" :key="starter" @click="toggleSelect(starter)">
        <va-button v-if="!isSelected(starter)" class="select-button" size="small"><va-icon size="small" name="check_box_outline_blank" /></va-button>
        <va-button v-if="isSelected(starter)" class="select-button" size="small"><va-icon size="small" name="check_box" /></va-button>
        {{ getStarter(starter) }}
      </div>
    </div>
  </div>
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "Starters-View",
  data() {
    return {
      global: globalStore(),
      starterList: [],
      isGettingStarterList: false,
    };
  },
  props: ["refresh"],
  methods: {
    async getStarterList() {
      this.starterList = [];
      this.global.isSending = false;
      this.isGettingStarterList = true;
      var response = await this.global.webApiGet("People");
      if (!this.global.lastApiError) {
        this.starterList = response.data;
      }
      this.isGettingStarterList = false;
    },
    getStarter(starter) {
      let starterName = `${starter.peopleId} ${starter.firstName}`;
      if (starter.middleName) {
        starterName += ` ${starter.middleName}`;
      }
      starterName += ` ${starter.surname}`;
      return starterName;
    },
    selectStarter(starter) {
      this.global.selectedList.push(starter);
    },
    deselectStarter(starter) {
      this.global.selectedList = this.global.selectedList.filter(function (item) {
        return item.peopleId !== starter.peopleId;
      });
    },
    toggleSelect(starter) {
      if (this.isSelected(starter)) {
        this.deselectStarter(starter);
      } else {
        this.selectStarter(starter);
      }
    },
    isSelected(starter) {
      var selected = this.global.selectedList.filter((f) => f.peopleId == starter.peopleId);
      return selected.length != 0;
    },
    async sendForms() {
      await this.global.webApiPost("ReleaseForms", this.global.selectedList);
      if (!this.global.lastApiError) {
        this.global.isSending = false;
        this.global.selectedList = [];
        this.getStarterList();
      }
    },
  },
  watch: {
    "global.isSending": async function (isSending) {
      // watch it
      console.log("SEND STATE CHANGED", isSending);
      if (isSending) {
        await this.sendForms();
      }
    },
  },
  async mounted() {
    if (!this.global.token) {
      this.$router.push("/login");
    }
    this.getStarterList();
  },
  components: {},
};
</script>

<style>
.starters-list {
  margin-left: 25px;
  margin-top: 25px;
  text-align: left;
}

.starter-item {
  cursor: pointer;
  margin-bottom: 5px;
  padding-right: 10px;
}

.starter-item:hover {
  background-color: lightblue;
}

.select-button {
  margin-right: 10px;
}
</style>
