<template>
  <div id="page-header"></div>
  <div id="logo-block">
    <div class="row">
      <div class="flex flex-col md5">
        <img src="/logo.png" />
      </div>
      <div class="flex flex-col md5">
        <VaTabs v-model="selectedTabIndex" @update:model-value="changeTab" v-if="global.token">
          <template #tabs>
            <VaTab v-for="tab in ['New Starters', 'Documents']" :key="tab">
              {{ tab }}
            </VaTab>
          </template>
        </VaTabs>
      </div>
      <div id="logout" class="flex flex-col md2">
        <VaButton :loading="global.isSending" class="send-form-button" v-if="global.token && global.selectedList.length > 0" @click="global.isSending = true">Send Forms</VaButton>
        <VaButton class="logout-button" v-if="global.token" @click="logout">Log Out</VaButton>
      </div>
    </div>
  </div>
  <div id="content-container">
    <div id="page-container">
      <div class="row">
        <div class="flex flex-col md2"></div>
        <div class="flex flex-col">
          <router-view />
        </div>
        <div class="flex flex-col md2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "App",
  data() {
    return {
      hello: "world",
      navMinimized: false,
      activeSidebarItem: 0,
      global: globalStore(),
      selectedTabIndex: 0,
      refresh: false
    };
  },
  components: {},
  methods: {
    logout() {
      this.global.token = null;
      this.global.userLevel = 0;
      this.global.selectedList = [];
      this.$router.push("/login");
    },
    changeTab() {
      switch(this.selectedTabIndex) {
        case 0:
          this.$router.push("/Starters");
          break;
        case 1:
          this.$router.push("/Documents");
          break;
      }

    }
  },
};
</script>

<style>
#content-container {
  overflow-y: auto;
  max-height: calc(100vh - 124px) !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

#page-container {
  padding: 0px !important;
}

#page-header {
  width: 100%;
  background-color: #053b77;
  border-color: #ffffff;
  height: 40px;
}

#logo-block {
  height: 83px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  border-bottom: 1px solid #053b77;
}

#logo-block .save_button {
  margin-right: 15px;
  margin-top: 8px;
}

html {
  overflow-y: auto !important;
  font-size: 0.9rem !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  overflow: hidden;
}

#logout {
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
}

.send-form-button {
  margin-right:10px;
}
</style>
