<template>
  <div>

    <div class="file-list">
      <div v-if="isGettingFileList">Getting files, please wait...</div>
      <div class="folder-item" v-if="activeFolder">
        <va-button class="folder-button" size="small" @click="selectFolder('')"><va-icon size="small" class="folder-icon" name="folder" /></va-button>
        <a href="javascript:;" @click="selectFolder('')">..</a>
      </div>
      <div class="folder-item" v-for="folder in folderList" :key="folder">
        <va-button class="folder-button" size="small" @click="selectFolder(folder)"><va-icon size="small" class="folder-icon" name="folder" /></va-button>   
        <a href="javascript:;" @click="selectFolder(folder)">{{ folder }}</a>
      </div>
      <div class="file-item" v-for="file in fileList" :key="file">
        <va-button :disabled="deletingFile==file" class="download-button" size="small" :loading="downloadingFile==file" @click="downloadFile(file)"><va-icon size="small" class="download-icon" name="download" /></va-button>
        {{ file }}
      </div>
    </div>

  </div>
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "Documents-View",
  data() {
    return {
      global: globalStore(),
      fileList: [],
      folderList: [],
      isGettingFileList: false,
      downloadingFile: "",
      activeFolder: "",
    };
  },
  methods: {
    async downloadFile(fileName) {
      this.downloadingFile=fileName;
      let folder = "root";
      if(this.activeFolder !="") {
        folder = this.activeFolder;
      }
      await this.global.webApiDownload(`DownloadDocument/${folder}/${fileName}`, fileName);
      this.downloadingFile="";
    }, 
    async getFileList() {
      let folder = "root";
      if(this.activeFolder !="") {
        folder = this.activeFolder;
      }
      this.isGettingFileList = true;
      var response = await this.global.webApiGet(`Documents/${folder}`);
      if (!this.global.lastApiError) {
        this.fileList = response.data.files;
        this.folderList = response.data.folders;
      }
      this.isGettingFileList = false;
    },
    async selectFolder(folder) {
      this.activeFolder = folder;
      await this.getFileList();
    }
  },
  async mounted() {
    if (!this.global.token) {
      this.$router.push("/login");
    }
    this.getFileList();
  },
  components: {},
};
</script>

<style>



.file-list {
  margin-left: 25px;
  margin-top: 25px;
  text-align: left;
}

.file-list h1 {
  margin-bottom: 25px;
}

.file-item {
  margin-top: 20px;
  margin-bottom: 20px;
}

.folder-button {
  margin-right:10px;
}

.download-button {
  margin-right:10px;
}

.delete-button {
  margin-right:10px;
}


</style>
